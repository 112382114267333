exports.components = {
  "component---src-pages-blogs-contentful-simply-fi-blog-page-slug-js": () => import("./../../../src/pages/blogs/{contentfulSimplyFiBlogPage.slug}.js" /* webpackChunkName: "component---src-pages-blogs-contentful-simply-fi-blog-page-slug-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-card-category-mongodb-mmcnew-categorydatas-key-js": () => import("./../../../src/pages/card-category/{mongodbMmcnewCategorydatas.key}.js" /* webpackChunkName: "component---src-pages-card-category-mongodb-mmcnew-categorydatas-key-js" */),
  "component---src-pages-card-details-mongodb-mmcnew-carddatas-key-js": () => import("./../../../src/pages/card-details/{mongodbMmcnewCarddatas.key}.js" /* webpackChunkName: "component---src-pages-card-details-mongodb-mmcnew-carddatas-key-js" */),
  "component---src-pages-cards-mongodb-mmcnew-bankdatas-key-js": () => import("./../../../src/pages/cards/{mongodbMmcnewBankdatas.key}.js" /* webpackChunkName: "component---src-pages-cards-mongodb-mmcnew-bankdatas-key-js" */),
  "component---src-pages-compare-cards-js": () => import("./../../../src/pages/compare-cards.js" /* webpackChunkName: "component---src-pages-compare-cards-js" */),
  "component---src-pages-find-my-match-cards-for-me-js": () => import("./../../../src/pages/find-my-match/cards-for-me.js" /* webpackChunkName: "component---src-pages-find-my-match-cards-for-me-js" */),
  "component---src-pages-find-my-match-index-js": () => import("./../../../src/pages/find-my-match/index.js" /* webpackChunkName: "component---src-pages-find-my-match-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intellectual-property-js": () => import("./../../../src/pages/intellectual-property.js" /* webpackChunkName: "component---src-pages-intellectual-property-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

